import React, { useMemo } from "react";

import { PanelProps, DataFrame, DataFrameType, Field, FieldType } from "@grafana/data";
import { PanelDataErrorView } from "@grafana/runtime";
import { TooltipDisplayMode } from "@grafana/schema";
import {
  KeyboardPlugin,
  TimeSeries,
  TooltipPlugin,
  usePanelContext,
  ZoomPlugin,
  MenuItemProps,
} from "@grafana/ui";
import { config } from "config";
import { getFieldLinksForExplore } from "features/explore/utils/links";

import { Options } from "components/TimeSeriesPanel/panelcfg.gen";
import { AnnotationsPlugin } from "./AnnotationPlugin";
import { AnnotationEditorPlugin } from "./AnnotationEditorPlugin";
import { ContextMenuPlugin } from "./ContextMenuPlugin";
import { ExemplarsPlugin, getVisibleLabels } from "./ExemplarsPlugin";
import { OutsideRangePlugin } from "./OutsideRangePlugin";
import { ThresholdControlsPlugin } from "./ThresholdControlsPlugin";
import { getPrepareTimeseriesSuggestion } from "./suggestions";
import { getTimezones, prepareGraphableFields } from "./utils";

// todo: move into dedicated module with constants.
export enum SIGNAL_FEATURE {
  Vibration = "vibration",
  Sound = "sound",
  Temperature = "temperature",
  Status = "status",
}
interface TimeSeriesPanelProps extends PanelProps<Options> {
  signalFeature?: SIGNAL_FEATURE;
  measurePoints?: string[];
}

export const TimeSeriesPanel = ({
  data,
  timeRange,
  timeZone,
  width,
  height,
  options,
  fieldConfig,
  onChangeTimeRange,
  id,
  signalFeature,
  measurePoints,
}: TimeSeriesPanelProps) => {
  const {
    sync,
    canAddAnnotations,
    onThresholdsChange,
    canEditThresholds,
    showThresholds,
    onSplitOpen,
  } = usePanelContext();

  const frames = useMemo(() => {
    const preparedFrames = prepareGraphableFields(data.series, config.theme2, timeRange);
    preparedFrames?.forEach(frame => {
      frame.fields.forEach(field => {
        if (field.type === FieldType.number) {
          const FeatureName = frame.refId;
          switch (FeatureName) {
            case 'VibrationX':
              field.config.color = {
                mode: 'fixed',
                fixedColor: 'red', 
              };
              field.config.max = 1;
              break;
            case 'VibrationY':
              field.config.color = {
                mode: 'fixed',
                fixedColor: 'green', 
              };
              field.config.max = 1;
              break;
            case 'VibrationZ':
              field.config.color = {
                mode: 'fixed',
                fixedColor: 'blue', 
              };
              field.config.max = 1;
              break;
              case 'Sound':
              field.config.color = {
                mode: 'fixed',
                fixedColor: '#b877d9', 
              };
              field.config.max = 0;
              break;
              case 'Temperature':
                field.config.color = {
                  mode: 'fixed',
                  fixedColor: '#e0b400', 
                };
                field.config.max = 80;
                break;
            default:
              field.config.color = {
                mode: 'fixed',
                fixedColor: '#000000',
              };
              field.config.max = 1;
              break;
          }
        }
      });
    });
  
    return preparedFrames;
  }, [data.series, timeRange]);
  const timezones = useMemo(
    () => getTimezones(options.timezone, timeZone),
    [options.timezone, timeZone]
  );
  const suggestions = useMemo(() => {
    if (
      frames?.length &&
      frames.every((df) => df.meta?.type === DataFrameType.TimeSeriesLong)
    ) {
      const s = getPrepareTimeseriesSuggestion(id);
      return {
        message: "Long data must be converted to wide",
        suggestions: s ? [s] : undefined,
      };
    }
    return undefined;
  }, [frames, id]);
  let scales: string[] = [];

  const emptyDataFrame: DataFrame = {
    fields: [
      { name: 'time', type: FieldType.time, values: [], config: {} },
      { name: 'value', type: FieldType.number, values: [], config: {} },
    ],
    length: 0,
  };

  const getFieldLinks = (field: Field, rowIndex: number) => {
    return getFieldLinksForExplore({
      field,
      rowIndex,
      splitOpenFn: onSplitOpen,
      range: timeRange,
    });
  };

  if (!frames || suggestions) {
    return (
      <PanelDataErrorView
        panelId={id}
        message={suggestions?.message}
        fieldConfig={fieldConfig}
        data={data}
        needsTimeField={true}
        needsNumberField={true}
        suggestions={suggestions?.suggestions}
      />
    );
  }

  const enableAnnotationCreation = Boolean(
    canAddAnnotations && (options.allowViewerAnnotation || canAddAnnotations())
  );

  const filterAnnotationsByTag = (dataFrame: DataFrame, tags: string[]): DataFrame => {
    if (typeof dataFrame === "undefined") {
      return dataFrame;
    }

    const tagsField = dataFrame.fields.find(field => field.name === 'tags');
    if (!tagsField) {
      return dataFrame;
    }

    const filteredValues = dataFrame.fields.map(field => ({...field, values: [] as string[]}));

    for (let i = 0; i < dataFrame.length; i++) {
      if (tagsField.values[i].some((it: string) => tags.includes(it))) {
        dataFrame.fields.forEach((field, index) => {
          filteredValues[index].values.push(field.values[i]);
        });
      }
    }

    return {
      fields: filteredValues,
      length: filteredValues[0].values.length,
      meta: dataFrame.meta
    };
  };

  const getFilterByMeasurePointsTags = (measurePoints?: string[]): string[] => {
    return (typeof measurePoints !== "undefined" && measurePoints) ? measurePoints.map(it => `mp:${it}`) : [];
  }

  const getFilterByFeatureTags = (feature?: SIGNAL_FEATURE): string[] => {
    const commonTags = [`feature:all`, `feature:all`];
    const vibTags = [`feature:vibration`, ...commonTags];
    const statusTags = [`feature:status`, ...commonTags];
    const soundTags = [`feature:sound`, ...commonTags];
    const tempTags = [`feature:temperature`, ...commonTags];

    if (feature === SIGNAL_FEATURE.Sound) {
      return soundTags;
    } else if (feature === SIGNAL_FEATURE.Temperature) {
      return tempTags;
    } else if (feature === SIGNAL_FEATURE.Status) {
      return statusTags;
    } else if (feature === SIGNAL_FEATURE.Vibration) {
      return vibTags
    }
    return [`feature:vibration`,`feature:status`, `feature:sound`, `feature:temperature`, ...commonTags]
  }

  const mpFilteredAnnotations = (typeof data.annotations !== "undefined") ? [filterAnnotationsByTag(data.annotations[0], getFilterByMeasurePointsTags(measurePoints))] : data.annotations;
  const filteredAnnotations = (typeof mpFilteredAnnotations !== "undefined") ? [filterAnnotationsByTag(mpFilteredAnnotations[0], getFilterByFeatureTags(signalFeature))] : data.annotations;
  const annotations = (
    filteredAnnotations === undefined || filteredAnnotations[0] === undefined
  ) ? [emptyDataFrame] : filteredAnnotations;

  return (
    <>
      <TimeSeries
        frames={frames}
        structureRev={data.structureRev}
        timeRange={timeRange}
        timeZone={timezones}
        // Chris: To be changed, should not be a hardcoded -400
        width={width}
        // Chris: To be changed, should not be a hardcoded -365
        height={height}
        legend={options.legend}
        options={options}
      >
        {(config, alignedDataFrame) => {
          const defaultContextMenuItems: MenuItemProps[] = scales.length
            ? [
                {
                  label: "Custom scales",
                  ariaLabel: "Custom scales",
                  icon: "channel-add",
                },
              ]
            : [];

          return (
            <>
              <KeyboardPlugin config={config} />
              <ZoomPlugin config={config} onZoom={onChangeTimeRange} />
              {options.tooltip.mode === TooltipDisplayMode.None || (
                <TooltipPlugin
                  frames={frames}
                  data={alignedDataFrame}
                  config={config}
                  mode={options.tooltip.mode}
                  sortOrder={options.tooltip.sort}
                  sync={sync}
                  timeZone={timeZone}
                />
              )}
              {/* Renders annotation markers*/}
              {annotations && (
                <AnnotationsPlugin
                  annotations={annotations}
                  config={config}
                  timeZone={timeZone}
                />
              )}
              {/* Enables annotations creation*/}
              {enableAnnotationCreation ? (
                <AnnotationEditorPlugin
                  data={alignedDataFrame}
                  timeZone={timeZone}
                  config={config}
                  options={options}
                  signalFeature={signalFeature}
                >
                  {({ startAnnotating }) => {
                    return (
                      <ContextMenuPlugin
                        data={alignedDataFrame}
                        config={config}
                        timeZone={timeZone}
                        defaultItems={[
                          {
                            items: [
                              {
                                label: "Add annotation",
                                ariaLabel: "Add annotation",
                                icon: "comment-alt",
                                onClick: (e, p) => {
                                  if (!p) {
                                    return;
                                  }
                                  startAnnotating({ coords: p.coords });
                                },
                              },
                              ...defaultContextMenuItems,
                            ],
                          },
                        ]}
                      />
                    );
                  }}
                </AnnotationEditorPlugin>
              ) : (
                <ContextMenuPlugin
                  data={alignedDataFrame}
                  frames={frames}
                  config={config}
                  timeZone={timeZone}
                  defaultItems={[
                    {
                      items: defaultContextMenuItems,
                    },
                  ]}
                />
              )}
              {annotations && (
                <ExemplarsPlugin
                  visibleSeries={getVisibleLabels(config, frames)}
                  config={config}
                  exemplars={annotations}
                  timeZone={timeZone}
                  getFieldLinks={getFieldLinks}
                />
              )}
              {((canEditThresholds && onThresholdsChange) ||
                showThresholds) && (
                <ThresholdControlsPlugin
                  config={config}
                  fieldConfig={fieldConfig}
                  onThresholdsChange={
                    canEditThresholds ? onThresholdsChange : undefined
                  }
                />
              )}
              <OutsideRangePlugin
                config={config}
                onChangeTimeRange={onChangeTimeRange}
              />
            </>
          );
        }}
      </TimeSeries>
    </>
  );
};
