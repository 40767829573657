import { PanelPlugin } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { commonOptionsBuilder } from '@grafana/ui';
import { defaultGraphConfig, getGraphFieldConfig } from './components/TimeSeriesPanel/config';
import { FieldConfig, Options } from './components/TimeSeriesPanel/panelcfg.gen';
import { TimeSeriesSuggestionsSupplier } from './components/TimeSeriesPanel/suggestions';
import {EquipmentFeedbackPanel} from "./components/EquipmentFeedbackPanel"

export const plugin = new PanelPlugin<Options, FieldConfig>(EquipmentFeedbackPanel)
  .useFieldConfig(getGraphFieldConfig(defaultGraphConfig))
  .setPanelOptions((builder) => {
    commonOptionsBuilder.addTooltipOptions(builder);
    commonOptionsBuilder.addLegendOptions(builder);

    /**
     * Variables
     */
    const variables = getTemplateSrv().getVariables();
    const variableOptions = variables.map((vr) => ({
      label: vr.name,
      value: vr.name,
    }));

    builder
      .addRadio({
        path: 'allowViewerAnnotation',
        name: 'Allow to add annotations to Viewers',
        category: ['Annotations'],
        settings: {
          options: [
            { label: 'Enabled', value: true },
            { label: 'Disabled', value: false },
          ],
        },
        defaultValue: true,
      })
      .addSelect({
        path: 'variable',
        name: 'Select variable for Annotations',
        settings: {
          options: variableOptions,
        },
        category: ['Annotations'],
      });
  })
  .setSuggestionsSupplier(new TimeSeriesSuggestionsSupplier())
  .setDataSupport({ annotations: true, alertStates: true });
