import { css, cx } from "@emotion/css";
import React, { HTMLAttributes, useState } from "react";
import { usePopper } from "react-popper";

import {
  colorManipulator,
  DataFrame,
  getDisplayProcessor,
  GrafanaTheme2,
  TimeZone,
  FieldType,
  QueryVariableModel
} from "@grafana/data";
import {
  DEFAULT_ANNOTATION_COLOR,
  PlotSelection,
  Portal,
  useStyles2,
  useTheme2,
} from "@grafana/ui";
import { getTemplateSrv } from "@grafana/runtime";
import { getCommonAnnotationStyles } from "./styles";
import { AnnotationsDataFrameViewDTO } from "./types";

import { AnnotationEditorForm } from "./AnnotationEditorForm";
import { SIGNAL_FEATURE } from "./TimeSeriesPanel";

interface AnnotationEditorProps extends HTMLAttributes<HTMLDivElement> {
  data: DataFrame;
  timeZone: TimeZone;
  selection: PlotSelection;
  onSave: () => void;
  onDismiss: () => void;
  annotation?: AnnotationsDataFrameViewDTO;
  signalFeature?: SIGNAL_FEATURE;
  measurePoints?: string[];
}

export const AnnotationEditor = ({
  onDismiss,
  onSave,
  timeZone,
  data,
  selection,
  annotation,
  style,
  signalFeature,
}: AnnotationEditorProps) => {
  const theme = useTheme2();
  const styles = useStyles2(getStyles);
  const commonStyles = useStyles2(getCommonAnnotationStyles);
  const [popperTrigger, setPopperTrigger] = useState<HTMLDivElement | null>(
    null
  );
  const [editorPopover, setEditorPopover] = useState<HTMLDivElement | null>(
    null
  );

  const findSelectionLabels = (df: DataFrame, selection: PlotSelection): string[] => {
    const selectionIsTimeRange = Boolean(selection.min === selection.max);
    const timeField = df.fields.find(field => field.type === FieldType.time);
    if (!timeField) {
      return [];
    }
    const interval = [];
    const measureIds = new Set<string>();

    for (let i = 0; i < timeField.values.length; i++) {
      const timestamp = timeField.values[i];
      if (selectionIsTimeRange) {
        if (timestamp >= selection.min && timestamp <= selection.max) { interval.push(i); }
      } else {
        if (timestamp >= selection.min) { interval.push(i); }
      }
      if (timestamp > selection.max) { break; }
    }

    interval.forEach(pos => {
      df.fields.forEach(field => {
        if (field?.labels) {
          if (field.values[pos]) {
            const measureId = field?.labels?.measure_id;
            if (measureId) { measureIds.add(measureId); }
          }
        }
      });
    });

    return Array.from(measureIds.values());
  }

  const popper = usePopper(popperTrigger, editorPopover, {
    modifiers: [
      { name: "arrow", enabled: false },
      {
        name: "preventOverflow",
        enabled: true,
        options: {
          rootBoundary: "viewport",
        },
      },
    ],
  });

  let xField = data.fields[0];
  if (!xField) {
    return null;
  }
  const xFieldFmt =
    xField.display || getDisplayProcessor({ field: xField, timeZone, theme });
  const isRegionAnnotation = selection.min !== selection.max;

  const variablesMap = Object.fromEntries(
    getTemplateSrv()
      .getVariables()
      .map((it) => [it.id, it])
  );
  const allMeasurePoints = (variablesMap.MeasurePoint as QueryVariableModel).options.map(
    (it) => it.value as string
  );

  // after switching to components, we don't have measure point on label level.
  // const selectedMeasureIds = findSelectionLabels(data, selection);
  const selectedMeasureIds = allMeasurePoints;

  return (
    <Portal>
      <>
        <div // div overlay matching uPlot canvas bbox
          style={style}
        >
          <div // Annotation marker
            className={cx(
              css`
                position: absolute;
                top: ${selection.bbox.top}px;
                left: ${selection.bbox.left}px;
                width: ${selection.bbox.width}px;
                height: ${selection.bbox.height}px;
              `,
              isRegionAnnotation
                ? styles.overlayRange(annotation)
                : styles.overlay(annotation)
            )}
          >
            <div
              ref={setPopperTrigger}
              className={
                isRegionAnnotation
                  ? cx(commonStyles(annotation).markerBar, styles.markerBar)
                  : cx(
                      commonStyles(annotation).markerTriangle,
                      styles.markerTriangle
                    )
              }
            />
          </div>
        </div>

        <AnnotationEditorForm
          annotation={
            annotation ||
            ({
              time: selection.min,
              timeEnd: selection.max,
            } as AnnotationsDataFrameViewDTO)
          }
          timeFormatter={(v) => xFieldFmt(v).text}
          onSave={onSave}
          onDismiss={onDismiss}
          ref={setEditorPopover}
          style={popper.styles.popper}
          {...popper.attributes.popper}
          signalFeature={signalFeature}
          measurePoints={selectedMeasureIds || []}
        />
      </>
    </Portal>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    overlay: (annotation?: AnnotationsDataFrameViewDTO) => {
      const color = theme.visualization.getColorByName(
        annotation?.color || DEFAULT_ANNOTATION_COLOR
      );
      return css`
        border-left: 1px dashed ${color};
      `;
    },
    overlayRange: (annotation?: AnnotationsDataFrameViewDTO) => {
      const color = theme.visualization.getColorByName(
        annotation?.color || DEFAULT_ANNOTATION_COLOR
      );
      return css`
        background: ${colorManipulator.alpha(color, 0.1)};
        border-left: 1px dashed ${color};
        border-right: 1px dashed ${color};
      `;
    },
    markerTriangle: css`
      top: calc(100% + 2px);
      left: -4px;
      position: absolute;
    `,
    markerBar: css`
      top: 100%;
      left: 0;
      position: absolute;
    `,
  };
};
