import React from "react";
import { DataFrame, FieldType, PanelProps } from "@grafana/data";
import {PanelDataErrorView } from "@grafana/runtime";
import { Stack, Tabs, Tab, Box } from "@mui/material";
import MeasurePointsPanel from "./MeasurePointsPanel/MeasurePointsPanel";
import { Options } from "./TimeSeriesPanel/panelcfg.gen";
import AssetInformation from "./AssetInformation";
import OverviewPanel from "./OverviewPanel/OverviewPanel";
import AnnotationsPanel from "./AnnotationsPanel/AnnotationsPanel";
import AlertsPanel from "./AlertsPanel/AlertsPanel";
import SensorsPanel from "./SensorsPanel/SensorsPanel";

export interface EquipmentFeedbackPanelProps extends PanelProps<Options> {}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index } = props;

  return (
    <Stack sx={{ flexGrow: 1, height: "100%" }}>
      {value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
    </Stack>
  );
}

export const EquipmentFeedbackPanel: React.FC<EquipmentFeedbackPanelProps> = (
  props: EquipmentFeedbackPanelProps
) => {
  const { data, fieldConfig, id } = props;
  const [value, setValue] = React.useState(0);

  if (data.series.length === 0) {
    return (
      <PanelDataErrorView
        fieldConfig={fieldConfig}
        panelId={id}
        data={data}
        needsStringField
      />
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const timeSeries: DataFrame | undefined = data.series.find((series) =>
    series.fields.some((field) => field.type === FieldType.time)
  );

  if (!timeSeries) {
    return <div>No time series data available.</div>;
  }
  return (
    <Stack spacing={2} sx={{ height: "100%", overflow: "auto" }}>
    <AssetInformation data={data} />
    <Box sx={{ width: '100%', mt: 1 }}></Box>
    <Stack
        sx={{ width: "100%", boxShadow: 2, borderRadius: 1 }}
      >
        <Box sx={{ height: "20%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Data" {...a11yProps(0)} />
            <Tab label="Equipment Detail" {...a11yProps(1)} />
            <Tab label="Annotations" {...a11yProps(2)} />
            <Tab label="Alerts" {...a11yProps(3)} />
            <Tab label="Sensors" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <OverviewPanel {...props} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <MeasurePointsPanel {...props} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <AnnotationsPanel {...props}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <AlertsPanel {...props}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <SensorsPanel {...props}/>
        </CustomTabPanel>
      </Stack>
    </Stack>
  );
};
