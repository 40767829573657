import React from 'react';
import { Modal, Button} from '@grafana/ui';

interface ConfirmationModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onConfirm, onCancel }) => {
  return (
    <Modal title="Confirm Delete" isOpen={isOpen}>
      <div>Are you sure you want to delete this row?</div>
      <Modal.ButtonRow>
        <Button variant="secondary" fill="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Delete</Button>
      </Modal.ButtonRow>
    </Modal>
  );
};

export default ConfirmationModal;