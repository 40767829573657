import { Table } from "@grafana/ui";
import { DataFrame, FieldType, Field, getRawDisplayProcessor } from "@grafana/data";
import { EquipmentFeedbackPanelProps } from "components/EquipmentFeedbackPanel";
import React from "react";
import { getTemplateSrv } from "@grafana/runtime";
import { format } from 'date-fns';


interface SensorsPanelProps extends EquipmentFeedbackPanelProps {}

const SensorsPanel = (props: SensorsPanelProps) => {

  const { data, width, height } = props;

  const variables = getTemplateSrv().getVariables();
  const variablesMap = Object.fromEntries(
    variables.map((it: any) => [it.id, it])
  );

  const equipment = variablesMap["Equipment"];

  const getLatestMetrics = (dataFrame: DataFrame): DataFrame => {
    const timeField = dataFrame.fields.find(field => field.type === FieldType.time);
    if (!timeField) {
      throw new Error('Time field not found in the DataFrame');
    }

    const latestIndex = timeField.values.length - 1;

    const latestDataFrame = {
      fields: dataFrame.fields.map(field => ({
        name: field.name,
        type: field.type,
        config: { ...field.config, custom: { align: "left" } },
        values: [field.values.get(latestIndex)]
      })),
      length: 1
    };
    return latestDataFrame;
  }

  const getLatestMetricsBattery = (dataFrame: DataFrame): DataFrame => {
    const timeField = dataFrame.fields.find(field => field.type === FieldType.time);
    if (!timeField) {
      throw new Error('Time field not found in the DataFrame');
    }

    const latestIndex = timeField.values.length - 1;

    const latestDataFrame = {
      fields: dataFrame.fields.map(field => ({
        name: field.name,
        type: field.type,
        state: {},
        config: { custom: { align: "left" } },
        values: [field.values[latestIndex]],
        display: getRawDisplayProcessor()
      })),
      length: 1
    };

  latestDataFrame.fields.push({
    name: "Sensor",
    type: FieldType.string,
    values: [dataFrame.fields.find(field => field.name === "bat")?.labels?.sensor_id],
    state: {displayName: "Sensor", multipleFrames: false, seriesIndex: 2},
    config: { custom: {align: "left"}},
    display: getRawDisplayProcessor()
  });

  latestDataFrame.fields.push({
    name: "Measure ID",
    type: FieldType.string,
    values: [dataFrame.fields.find(field => field.name === "bat")?.labels?.measure_id],
    state: {displayName: "Measure ID", multipleFrames: false, seriesIndex: 2},
    config: { custom: {align: "left"}},
    display: getRawDisplayProcessor()
  });

  latestDataFrame.fields.push({
    name: "Equipment ID",
    type: FieldType.string,
    values: [equipment.current.value],
    state: {displayName: "Equipment ID", multipleFrames: false, seriesIndex: 3},
    config: { custom: {align: "left"}},
    display: getRawDisplayProcessor()
  });

  latestDataFrame.fields.push({
    name: "Equipment",
    type: FieldType.string,
    values: [equipment.current.text],
    state: {displayName: "Equipment", multipleFrames: false, seriesIndex: 4},
    config: { custom: {align: "left"}},
    display: getRawDisplayProcessor()
  });

    return latestDataFrame;
  }

  const unionDataFrames = (dataFrames: DataFrame[]): DataFrame => {
    if (dataFrames.length === 0) {
      throw new Error('No data frames provided');
    }

    const fields = dataFrames[0].fields.map(field => ({
      name: field.name,
      type: field.type,
      config: { ...field.config, custom: { align: "left" } },
      values: [] as any[],
      display: getRawDisplayProcessor()
    }));

    const unionDataFrame = {
      fields: fields,
      length: dataFrames.length,
    };

    dataFrames.forEach(dataFrame => {
      dataFrame.fields.forEach((field, index) => {
        unionDataFrame.fields[index].values.push(field.values[0]);
      });
    });

    return unionDataFrame;
  }

  // todo: add handling of undefined/null values
  // const emptyDataFrame: DataFrame = {
  //   fields: [
  //     { name: 'time', type: FieldType.time, values: [], config: {} },
  //     { name: 'value', type: FieldType.number, values: [], config: {} },
  //   ],
  //   length: 0,
  // };

  const prepareSensorsData = (batDf: DataFrame, statusDf: DataFrame, rssiDf: DataFrame): DataFrame => {
    const emptyField = (fName: string): Field => {
      return {
        name: fName,
        type: FieldType.string,
        values: [],
        state: {displayName: fName, multipleFrames: false, seriesIndex: 0},
        config: { custom: {align: "left"} },
        display: getRawDisplayProcessor()
      }
    }
    const timeField = batDf.fields.find(field => field.type === FieldType.time);
    const lastSeen = {
      name: "Last Seen",
      type: FieldType.string,
      values: timeField?.values.map(it => format(it, 'yyyy-MM-dd HH:mm:ss')) as string[],
      state: {displayName: "LastSeen", multipleFrames: false, seriesIndex: 6},
      config: { custom: {align: "left"}},
      display: getRawDisplayProcessor()
    };

    const equipment= batteryDf.fields.find(field => field.name === "Equipment") || emptyField("Equipment");
    const measureId = batteryDf.fields.find(field => field.name === "Measure ID") || emptyField("Measure ID");
    const sensor = batteryDf.fields.find(field => field.name === "Sensor") || emptyField("Sensor");
    const bat = batteryDf.fields.find(field => field.name === "bat") || emptyField("bat");
    const status = statusDf.fields[1] ? {
      name: "Status",
      type: FieldType.string,
      values: statusDf.fields[1].values.map(value => value === 1 ? "online" : "offline"),
      state: { displayName: "Status", multipleFrames: false, seriesIndex: 1 },
      config: { custom: { align: "left" } },
      display: getRawDisplayProcessor()
    } : emptyField("Status");
    const rssi = rssiDf.fields[1] || emptyField("Rssi");

    return {
      ...batteryDf,
      fields: [
        equipment,
        measureId,
        sensor,
        status,
        lastSeen,
        bat,
        rssi
      ]
    }
  }
  const batteryData = data.series.filter((x) => x.refId === "Battery").map(getLatestMetricsBattery);
  const statusData = unionDataFrames(data.series.filter((x) => x.refId === "Status").map(getLatestMetrics));
  const rssiData = unionDataFrames(data.series.filter((x) => x.refId === "Rssi").map(getLatestMetrics));


  const batteryDf = unionDataFrames(batteryData);
  const tableData = prepareSensorsData(batteryDf, statusData, rssiData);

  return (
    <div className={"AlertsTable"}>
      <>
        <div style={{ width: `${width}px`, height: `${height}px` }}>
          <Table
            height={height}
            width={width}
            data={tableData}
            noHeader={false}
            showTypeIcons={false}
            resizable={false}
          />
        </div>
      </>
    </div>
  );
};

export default SensorsPanel;
