import React, { useCallback, useEffect, useState } from "react";
import { DataFrame, FieldType, Field, getRawDisplayProcessor, AnnotationEventUIModel, QueryVariableModel } from "@grafana/data";
import { DataGrid, GridColDef, GridActionsCellItem, GridRowModesModel, GridRowId, GridRowModel } from "@mui/x-data-grid";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { EquipmentFeedbackPanelProps } from "components/EquipmentFeedbackPanel";
import { getTemplateSrv } from "@grafana/runtime";
import { format } from 'date-fns';
import { handleDeleteRow, handleRowEditStop } from "utils/dataGridUtils";
import ConfirmationModal from "components/AnnotationsPanel/ConfirmationModal";
import { usePanelContext } from "@grafana/ui";
import useAsyncFn from "react-use/lib/useAsyncFn";

interface AlertsPanelProps extends EquipmentFeedbackPanelProps {}

const AlertsPanel = (props: AlertsPanelProps) => {

  const { data, width, height } = props;

  const variables = getTemplateSrv().getVariables();
  const variablesMap = Object.fromEntries(
    variables.map((it: any) => [it.id, it])
  );

  const client = variablesMap["Client"];
  const equipment = variablesMap["Equipment"];
  const equipmentId = equipment.current.text.split('-')[1] || "";
  const selectedComponent = (variablesMap.Component as QueryVariableModel).current.text as string || "";
  const room = variablesMap["Room"];
  const [tags] = useState<string[] | undefined>(undefined);
  const [annotationId, setAnnotationId] = useState<Field | undefined>(undefined);
  const [tagsField, setTagsField] = useState<Field | undefined>(undefined);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<GridRowId | null>(null);
  const [annotationIdToDelete, setAnnotationIdToDelete] = useState<string | null>(null);
  const { canAddAnnotations, canEditAnnotations, canDeleteAnnotations, ...panelCtx } = usePanelContext();

  const emptyDataFrame: DataFrame = {
    fields: [
      { name: 'time', type: FieldType.time, values: [], config: {} },
      { name: 'value', type: FieldType.number, values: [], config: {} },
    ],
    length: 0,
  };

  const filterAnnotationsByTag = (dataFrame: DataFrame, tags: string[]): DataFrame => {
    const tagsField = dataFrame.fields.find(field => field.name === 'tags');
    if (!tagsField) {
      return dataFrame;
    }

    const filteredValues = dataFrame.fields.map(field => ({...field, values: [] as string[]}));

    for (let i = 0; i < dataFrame.length; i++) {
      if (tagsField.values[i].some((it: string) => tags.includes(it))) {
        dataFrame.fields.forEach((field, index) => filteredValues[index].values.push(field.values[i]));
      }
    }

    return {
      fields: filteredValues,
      length: filteredValues[0].values.length,
      meta: dataFrame.meta
    };
  };

  const getFeatureFromTags = (tagValues: string[][], feature: string): string[] => {
    return tagValues.map(inArr => inArr.filter(item => item.startsWith(`${feature}:`)).map(it => it?.split(":")[1]).join(", ") || "n/a");
  }

  const constructNewFieldFromTags = (inField: Field, displayName: string, feature: string, index: number): Field => {
    return {
      name: displayName,
      type: FieldType.string,
      values: getFeatureFromTags(inField.values, feature),
      state: {displayName: displayName, multipleFrames: false, seriesIndex: index},
      config: { custom: {}},
      display: inField.display
    };
  }

  const constructField = (displayName: string, fieldType: FieldType, values: string[], index: number): Field => {
    return {
      name: displayName,
      type: fieldType,
      values: values,
      config: { custom: {}}, state: {displayName: displayName, multipleFrames: false, seriesIndex: index},
      display: getRawDisplayProcessor()
    }
  }

  const prepareAnnotationsData = (data: DataFrame | undefined): { data: DataFrame; annotationId: Field; tagsField: Field } => {
    if (!data) {
      return { data: emptyDataFrame, annotationId: {} as Field, tagsField: {} as Field };
    }

    const newFields: { [key: string]: Field } = {};
    const Annotationid = data.fields?.find((field) => field.name === 'id')!;
    const timeStartField = data.fields?.find((field) => field.name === 'time')!;
    const timeEndField = data.fields.find((field) => field.name === 'timeEnd')!;
    const tagsField = data.fields.find((field) => field.name === 'tags')!;
    const numValues = data.length;

    newFields["Time Start"] = {...timeStartField, name: "Time Start", type: FieldType.string, values: timeStartField.values.map(it => format(it, 'yyyy-MM-dd HH:mm:ss'))};
    newFields["Time End"] = {...timeEndField, name: "Time End", type: FieldType.string, values: timeEndField.values.map(it => format(it, 'yyyy-MM-dd HH:mm:ss'))};
    newFields["Component"] = constructNewFieldFromTags(tagsField, "Component", "component", 3);
    newFields["Equipment"] = constructField("Equipment", FieldType.string, Array(numValues).fill(equipment.current.text), 1);
    newFields["Measure ID"] = constructNewFieldFromTags(tagsField, "Measure ID", "mp", 2);
    newFields["Client"] = constructField("Client", FieldType.string, Array(numValues).fill(client.current.value), 3);
    newFields["Alert Type"] = constructField("Alert Type", FieldType.string, Array(numValues).fill("Anomaly"), 4);
    newFields["Equipment Room"] = constructField("Equipment Room", FieldType.string, Array(numValues).fill(room.current.text), 5);
    newFields["Severity"] = constructNewFieldFromTags(tagsField, "Severity", "severity", 6);
    newFields["Labeler"] = constructNewFieldFromTags(tagsField, "Labeler", "labeler", 7);
    newFields["Customer Interaction"] = constructNewFieldFromTags(tagsField, "Customer Interaction", "customerInteraction", 8);
  
    return {
      data: {
        fields: Object.values(newFields),
        length: data.length,
      },
      annotationId: Annotationid,
      tagsField: tagsField
    };
  }

  const annotationsData = (data.annotations !== undefined && data.annotations[0] !== undefined) ? data.annotations[0] : emptyDataFrame ;
  const alerts = filterAnnotationsByTag(annotationsData, ["alert"]);
  const [tableData, setTableData] = useState<DataFrame>(emptyDataFrame);

  useEffect(() => {
    if (data.annotations && data.annotations.length > 0) {
     const { data, annotationId,tagsField } = prepareAnnotationsData(alerts);
     setTableData(data);
     setAnnotationId(annotationId);
     setTagsField(tagsField);
    }
  }, [alerts, data.annotations]);

  const triggerEvent = (
    eventId: string,
    tags: string[] | undefined,
    updatedRow: GridRowModel,
    Annotationtags:any,
     ) => {
      const measureIdTags = updatedRow?.["Measure ID"]
        ? updatedRow?.["Measure ID"]
              .split(",")
              .map((id: string) => `mp:${id.trim()}`)
        : [];
        const anomalyType = getFeatureFromTags([Annotationtags], "anomalyType")[0] || "n/a";
        const Trend = getFeatureFromTags([Annotationtags], "trend")[0] || "n/a";
        const Feature = getFeatureFromTags([Annotationtags], "feature")[0] || "n/a";
        const EquipmentOperation = getFeatureFromTags([Annotationtags], "equipmentOperation")[0] || "n/a";
        const Component = getFeatureFromTags([Annotationtags], "component")[0] || "n/a";

     updateAnnotation({
      id: eventId,
      tags: [
        `severity:${updatedRow.Severity || 'N/A'}`,
        `anomalyType:${anomalyType}`,
        `trend:${Trend}`,
        `feature:${Feature}`,
        `component:${Component}`,
        `equipmentOperation:${EquipmentOperation}`,
        `labeler:${updatedRow?.["Labeler"]}`,
        ...measureIdTags,
         `alert:firing`, 
        `alert`, 
        ...new Set(tags),
      ],
      description: updatedRow.Description || "No description provided",
      from: new Date(updatedRow?.["Time Start"]).getTime(),
      to: new Date(updatedRow?.["Time End"]).getTime(),
    });
  }

  const [updateAnnotationState, updateAnnotation] = useAsyncFn(
    async (event: AnnotationEventUIModel) => {
      const result = await panelCtx.onAnnotationUpdate!(event);
      return result;
    }
  );

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = newRow;
    const eventId=annotationId?.values[Number(updatedRow.id)]
    const Annotationtags = tagsField?.values[Number(updatedRow.id)];
    triggerEvent(eventId,tags,updatedRow,Annotationtags);
    return newRow; 
  };

  const handleSwitchChange = async (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
  const newValue = event.target.checked ? 'acknowledged' : 'Sent';
  const updatedRow = rows.find(row => row.id === id);
    if (!updatedRow) {
        console.error("Row not found");
        return;
    }
    const updatedTags = [
      ...new Set(tags?.filter(tag => !tag.startsWith("customerInteraction:"))),
      `customerInteraction:${newValue}`,
  ];
    const eventId = annotationId?.values[Number(updatedRow.id)];
    const Annotationtags = tagsField?.values[Number(updatedRow.id)];
    triggerEvent(eventId, updatedTags, updatedRow,Annotationtags);
  };

  
  const openModal = (id: GridRowId) => {
    const annotationIdValue = annotationId?.values[Number(id)];
    setRowToDelete(id);
    setAnnotationIdToDelete(annotationIdValue);  
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setRowToDelete(null);
  };
  const onAnnotationDelete = useCallback((annotationId: string) => {
    if (panelCtx.onAnnotationDelete) {
      panelCtx.onAnnotationDelete(annotationId);
    }
  }, [panelCtx]);
  
  const confirmDelete = () => {
    if ((rowToDelete !== null && annotationIdToDelete !== null)) {
      onAnnotationDelete(annotationIdToDelete);
      handleDeleteRow(rowToDelete, tableData, setTableData);
      closeModal();
    }
  };
  const columns: GridColDef[] = [
    ...tableData.fields.map((field) => {
      let colDef: GridColDef = {
        field: field.name,
        headerName: field.name,
        width: 220,
        editable: false,
        sortable: true,
        flex: 1,
      };
      if (field.name === "Customer Interaction") {
        colDef = {
          ...colDef,
          renderCell: (params) => {
            // Use useState to track the switch state
            const [checked, setChecked] = useState(
              params.value === "acknowledged"
            );
  
            // Handle the switch toggle
            const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
              setChecked(event.target.checked);
              handleSwitchChange(params.id.toString(), event); // Trigger state update and processRowUpdate
            };
            return (
              <FormControlLabel
                control={<Switch checked={checked} onChange={handleChange} />}
                label="Acknowledged"
              />
            );
          },
        };
      }
      return colDef;
    }),
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [ 
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => openModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  
  const rows = Array.from({ length: tableData.length }).map((_, index) => {
    const row: { id: string; [key: string]: any } = { id: index.toString() };
    tableData.fields.forEach(field => {
      row[field.name] = field.values[index];
    });
    return row;
  }).filter(row => {
    const component = row["Component"];
    return component== selectedComponent.split('-')[0] && component.includes(equipmentId);
  });

  return (
    <div className={"AlertsTable"}>
      <div style={{ width: `${width}px`, height: `${height}px` }}>
        <DataGrid
          columns={columns}
          rows={rows}
          editMode="row"
          processRowUpdate={(newRow) => processRowUpdate(newRow)}
          rowModesModel={rowModesModel}
          onRowModesModelChange={newModel => setRowModesModel(newModel)}
          onRowEditStop={handleRowEditStop}
          getRowId={(row) => row.id}
        />
      </div>
      {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          onConfirm={confirmDelete}
          onCancel={closeModal}
        />
      )}
    </div>
  );
};

export default AlertsPanel;
