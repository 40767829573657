import { PanelData } from "@grafana/data";
import { getTemplateSrv } from "@grafana/runtime";
import { Chip/* , TextField */ } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React/* , { useState } */ from "react";

interface AssetInformationFieldValuesProps {
  field: string;
  value: string;
}

const AssetInformationFieldValues: React.FC<
  AssetInformationFieldValuesProps
> = ({ field, value }) => {
  return (
    <>
      <Grid item xs={6}>
        <Typography fontWeight="700" variant="subtitle2">
          {field}:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {field === "EquipmentStatus" ? (
          <Chip
            label={value}
            size="small"
            color={
              value === "On" ? "success" :
              value === "Off" ? "error" :
              "default"}
          />
        ) : (
          <Typography variant="subtitle2" style={{ color: value === "undefined" ? "grey" : "inherit" }}>{value}</Typography>
        )}
      </Grid>
    </>
  );
};

interface AssetInformationProps {
  data: PanelData;
}
const AssetInformation = (props: AssetInformationProps) => {
 // const [description, setDescription] = useState("");

  const variables = getTemplateSrv().getVariables();
  const variablesMap = Object.fromEntries(
    variables.map((it: any) => [it.id, it])
  );

  const client = variablesMap["Client"];
  const equipment = variablesMap["Equipment"];
  const measurePoint = variablesMap["MeasurePoint"];
  const room = variablesMap["Room"];


  /* const onDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  }; */

  const statusField = props.data.series.find((x) => x.refId === "EquipmentStatus")?.fields[1]?.values[0];

  const EquipmentStatus = (() => {
      switch (statusField) {
          case undefined:
              return "Undefined";
          case 0:
              return "Off";
          default:
              return "On";
      }
  })();

  const UsageLastDayDataSeries = props.data.series.find(
    (x) => x.refId === "Usage"
  );

  const UsageLastDay =
    UsageLastDayDataSeries?.fields[1]?.values[
      UsageLastDayDataSeries?.fields[1]?.values.length - 1
    ].toString();

  const ActiveAlerts = props.data.series
    .find((x) => x.refId === "ActiveAlerts")
    ?.fields[1].values[0].toString();
  return (
    <Box sx={{ height: "20%", display: "flex", p: 3 }}>
      <Box
        component="img"
        src={`https://public-sensor-photos.s3.amazonaws.com/Installations/${equipment.current.value}.jpg`}
        alt="Equipment Image"
        sx={{
          width: 140, // directly using numerical values translates to 'px'
          height: 140,
          objectFit: "cover",
          borderRadius: 1,
        }}
      />
      <Grid container spacing={0} sx={{ flex: 2, p: 2 }} alignItems="center">
        <AssetInformationFieldValues
          field="Organization"
          value={client.current.text}
        />
        <AssetInformationFieldValues
          field="Equipment Room"
          value={room.current.value}
        />
        <AssetInformationFieldValues
          field="Equipment"
          value={equipment.current.text}
        />
        <AssetInformationFieldValues field="EquipmentStatus" value={EquipmentStatus} />
        <AssetInformationFieldValues
          field="Usage (Last 24H)"
          value={UsageLastDay + "%"}
        />
        <AssetInformationFieldValues
          field="Active alerts"
          value={ActiveAlerts}
        />
        <AssetInformationFieldValues
          field="Measure points"
          value={measurePoint.options.length.toString()}
        />   
      </Grid>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flex: 2 }}>
{/*         <TextField
          sx={{
            width: "100%",
            height: "300px",
            maxHeight: "100%",
          }}
          label="Asset information"
          value={description}
          variant="outlined"
          onChange={onDescriptionChange}
          size="medium"
          multiline
        />  */}
      </Box>
    </Box>
  );
};

export default AssetInformation;
