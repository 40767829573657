export const featureOptions = [
    { label: "TEMP", value: "temperature" },
    { label: "VIBRATION", value: "vibration" },
    { label: "SOUND", value: "sound" },
    { label: "STATUS", value: "status" },
    { label: "ALL", value: "all" },
  ];
  
  export const severityOptions = [
    { label: "WARNING", value: "warning" },
    { label: "CRITICAL", value: "critical" },
    { label: "INFO", value: "info" },
  ];
  
  export const anomalyTypeOptions = [
    { label: "SUDDEN", value: "sudden" },
    { label: "GRADUAL", value: "gradual" },
    { label: "OTHERS", value: "others" },
  ];
  
  export const trendOptions = [
    { label: "INCREASE", value: "increase" },
    { label: "DECREASE", value: "decrease" },
    { label: "CHANGE", value: "change" },
    { label: "OTHERS", value: "others" },
  ];
  export const equipmentOperationOptions = [
    { label: "NOT_EXPECTED", value: "NOT_EXPECTED" },
    { label: "EXPECTED", value: "EXPECTED" },
  ];
  