import { getTemplateSrv } from "@grafana/runtime";
import { Box, List, ListItemButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import { EquipmentFeedbackPanelProps } from "components/EquipmentFeedbackPanel";
import React, { useEffect, useState } from "react";
import MeasurePointsListItem from "./MeasurePointsListItem";
import { DataFrame, QueryVariableModel } from "@grafana/data";

interface MeasurePoint {
  imageURL: string;
  id: string;
  sensorId: string;
  position: string;
  status: string;
  batteryVoltage: string;
  LifeExpectancy: string;
  DischargeRate: string;
}

interface MeasurePointsPanelProps extends EquipmentFeedbackPanelProps {}

const MeasurePointsPanel = (props: MeasurePointsPanelProps) => {
  const [_, setVariables] = useState(getTemplateSrv().getVariables());
  const [measurePointList, setMeasurePointList] = useState<MeasurePoint[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { data, height, width, fieldConfig, ...otherProps } = props;
  const Sensor=data.series.filter((x) => x.refId === "SensorDetails");
  const BatteryModel=data.series.find((x: DataFrame) => x.refId === "BatteryModel");

  useEffect(() => {
    const newVariables = getTemplateSrv().getVariables();
    setVariables(newVariables);

    const measurePoint = newVariables.find((v) => v.id === "MeasurePoint");
    if (measurePoint) {
      const newMeasurePointList = (measurePoint as QueryVariableModel).options.map((mp) => {
        const sensorData = Sensor.find((sensor) => sensor.fields?.[1]?.labels?.measure_id === mp.text);
        const lifeExpectancyField = BatteryModel?.fields?.find(field => field.name === 'LifeExpectancy');
        const DischargeRateField = BatteryModel?.fields?.find(field => field.name === 'DischargeComparedToModel');
        const measurePointIndex = BatteryModel?.fields?.find(field => field.name === 'MeasurePointID')?.values.findIndex((id) => id === mp.text);

        return {
          imageURL: `https://public-sensor-photos.s3.amazonaws.com/Installations/${mp.text}.jpg`,
          id: mp.text,
          sensorId: sensorData?.fields?.[1]?.labels?.sensor_id ?? "N/A",
          position: sensorData?.fields?.[1]?.labels?.measure_location ?? "N/A",
          status: sensorData?.fields?.[1]?.values ? "online" : "offline",
          batteryVoltage:(sensorData?.fields?.[1]?.values?.[0] !== undefined ? parseFloat(sensorData?.fields?.[1]?.values?.[0]).toFixed(2) : "N/A") + " (V)",
          LifeExpectancy: ((measurePointIndex !== undefined && measurePointIndex >= 0 && lifeExpectancyField) ? lifeExpectancyField.values[measurePointIndex] : "N/A")+" (Day)",
          DischargeRate:(measurePointIndex !== undefined && measurePointIndex >= 0 && DischargeRateField) ? DischargeRateField.values[measurePointIndex] : "N/A",
        } as MeasurePoint;
      });
      setMeasurePointList(newMeasurePointList);
      setSelectedId(newMeasurePointList[0].id); // Ensure that the first item is selected initially
    }
  },
  [data.series]);

  const handleListItemClick = (id: string) => {
    setSelectedId(id);
  };

  return (
    <Stack sx={{ flexDirection: "row", height: "100%"}}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: "calc(100% - 10px)",
            "& ul": { padding: 0 },
            border: 1,
            borderColor: "lightgrey",
          }}
        >
          {measurePointList.map((mp: MeasurePoint, index: number) => {
            return (
              <ListItemButton
                sx={{
                  borderBottom: index === measurePointList.length - 1 ? 0 : 1,
                  borderColor: "lightgrey",
                }}
                onClick={() => handleListItemClick(mp.id)}
                selected={mp.id === selectedId}
                key={mp.id}
              >
                <MeasurePointsListItem {...mp} key={mp.id} />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Stack>
  );
};

export default MeasurePointsPanel;
