import { Box, Stack, SxProps, Typography } from "@mui/material";
import { EquipmentFeedbackPanelProps } from "components/EquipmentFeedbackPanel";
import {
  SIGNAL_FEATURE,
  TimeSeriesPanel,
} from "components/TimeSeriesPanel/TimeSeriesPanel";
import React, { useState, useEffect } from "react";
import { getTemplateSrv } from "@grafana/runtime";
import { QueryVariableModel } from "@grafana/data";

interface OverviewPanelProps extends EquipmentFeedbackPanelProps {}

export const TimeSeriesBoxCSS = {
  p: 1,
  border: 1,
  borderColor: "lightgrey",
} as SxProps;

const OverviewPanel = (props: OverviewPanelProps) => {
  const { data, height, width, fieldConfig, ...otherProps } = props;
  const variablesMap = Object.fromEntries(
    getTemplateSrv()
      .getVariables()
      .map((it) => [it.id, it])
  );
  // Measure points must be from the current component in order to filter annotations by component. 
  // const measurePoints = (variablesMap.MeasurePoint as QueryVariableModel).options.map((it) => it.value as string);
  const selectedComponent = (variablesMap.Component as QueryVariableModel).current.value as string || "";
  const measurePoints = selectedComponent.split("|");

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Stack sx={{ p: 2 }} spacing={2}>
      <Box sx={TimeSeriesBoxCSS}>
        <Typography variant="h5">Vibration (G)</Typography>
        <TimeSeriesPanel
          {...otherProps}
          height={(height - 260) / 3}
          data={{
            ...data,
            series: data.series.filter(
              (x) =>
                x.refId === "VibrationX" ||
                x.refId === "VibrationY" ||
                x.refId === "VibrationZ"
            ),
          }}
          width={width - 55}
          fieldConfig={fieldConfig}
          signalFeature={SIGNAL_FEATURE.Vibration}
          measurePoints={measurePoints}
        />
      </Box>
      <Box sx={TimeSeriesBoxCSS}>
        <Typography variant="h5">Status </Typography>
        <TimeSeriesPanel
          {...otherProps}
          height={(height - 260) / 3}
          data={{
            ...data,
            series: data.series.filter((x) => x.refId === "EquipmentStatus"),
          }}
          width={width - 55}
          fieldConfig={fieldConfig}
          signalFeature={SIGNAL_FEATURE.Status}
          measurePoints={measurePoints}
        />
      </Box>
      <Box sx={TimeSeriesBoxCSS}>
        <Typography variant="h5">Sound (dB)</Typography>
        <TimeSeriesPanel
          {...otherProps}
          height={(height - 260) / 3}
          data={{
            ...data,
            series: data.series.filter((x) => x.refId === "Sound"),
          }}
          width={width - 55}
          fieldConfig={fieldConfig}
          signalFeature={SIGNAL_FEATURE.Sound}
          measurePoints={measurePoints}
        />
      </Box>
      <Box sx={TimeSeriesBoxCSS}>
        <Typography variant="h5">Temperature (°C)</Typography>
        <TimeSeriesPanel
          {...otherProps}
          height={(height - 260) / 3}
          data={{
            ...data,
            series: data.series.filter((x) => x.refId === "Temperature"),
          }}
          width={width - 55}
          fieldConfig={fieldConfig}
          signalFeature={SIGNAL_FEATURE.Temperature}
          measurePoints={measurePoints}
        />
      </Box>
    </Stack>
  );
};

export default OverviewPanel;
