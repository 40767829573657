import React from "react";
import { ListItem, Box, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";

interface MeasurePointsFieldValuesProps {
  field: string;
  value: string;
}

const MeasurePointsFieldValues: React.FC<MeasurePointsFieldValuesProps> = ({
  field,
  value,
}) => {
  return (
    <Stack sx={{ flexDirection: "row" }}>
      <Grid item xs={6}>
        <Typography variant="subtitle2">{field}:</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2">{value}</Typography>
      </Grid>
    </Stack>
  );
};

interface MeasurePointListItemProps {
  imageURL: string;
  id: string;
  sensorId: string;
  position: string;
  status: string;
  batteryVoltage: string;
  LifeExpectancy: string;
  DischargeRate: string;
}

const MeasurePointsListItem = (props: MeasurePointListItemProps) => {
  const {
    imageURL,
    id,
    sensorId,
    position,
    status,
    batteryVoltage,
    LifeExpectancy,
    DischargeRate,
  } = props;
  return (
    <ListItem>
      <Stack sx={{ flexDirection: "row", gap: 4 }}>
        <Box
          component="img"
          src={imageURL}
          alt="Equipment Image"
          sx={{ width: 100, height: 100, objectFit: "cover", borderRadius: 2 }}
        />
        <Stack>
          <MeasurePointsFieldValues field="Measure ID" value={id} />
          <MeasurePointsFieldValues field="Sensor ID" value={sensorId} />
          <MeasurePointsFieldValues field="Position" value={position} />
          <MeasurePointsFieldValues field="Status" value={status} />
          <MeasurePointsFieldValues field="Battery" value={batteryVoltage} />
          <MeasurePointsFieldValues field="Life Expectancy" value={LifeExpectancy} />
          <MeasurePointsFieldValues field="Discharge Rate" value={DischargeRate} />
        </Stack>
      </Stack>
    </ListItem>
  );
};

export default MeasurePointsListItem;
