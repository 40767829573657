import { DataFrame} from "@grafana/data";
import { GridRowId, GridRowModes, GridRowModesModel, GridEventListener, GridRowEditStopReasons } from "@mui/x-data-grid";

export const prepareDeleteRow = (
    rowId: GridRowId, 
    setShowModal: (show: boolean) => void, 
    setRowToDelete: (id: GridRowId | null) => void
  ) => {
    setRowToDelete(rowId);
    setShowModal(true);
};
  
export const handleDeleteRow = (
    rowId: GridRowId, 
    tableData: DataFrame, 
    setTableData: (data: DataFrame) => void
  ) => {
    const updatedFields = tableData.fields.map(f => {
      const updatedValues = f.values.filter((_: any, index: number) => index !== Number(rowId));
      return { ...f, values: updatedValues };
    });
    setTableData({ ...tableData, fields: updatedFields, length: tableData.length - 1 });
};

export const handleEditClick = (id: GridRowId, rowModesModel: GridRowModesModel, setRowModesModel: (model: GridRowModesModel) => void) => () => {
  setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
};

export const handleSaveClick = (id: GridRowId, rowModesModel: GridRowModesModel, setRowModesModel: (model: GridRowModesModel) => void) => () => {
  setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
};

export const handleCancelClick = (id: GridRowId, rowModesModel: GridRowModesModel, setRowModesModel: (model: GridRowModesModel) => void, rows: any[], tableData: DataFrame, setTableData: (data: DataFrame) => void) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      handleDeleteRow(id, tableData, setTableData);
    }
  };

export const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
  if (params.reason === GridRowEditStopReasons.rowFocusOut) {
    event.defaultMuiPrevented = true;
  }
};