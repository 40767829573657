import { css, cx } from "@emotion/css";
import React, { HTMLAttributes, useRef, useState } from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";
import useClickAway from "react-use/lib/useClickAway";

import { AnnotationEventUIModel, GrafanaTheme2, QueryVariableModel } from "@grafana/data";
import { config } from '@grafana/runtime';
import {
  Button,
  Field,
  Form,
  HorizontalGroup,
  InputControl,
  TextArea,
  usePanelContext,
  useStyles2,
  Select,
} from "@grafana/ui";
import { getTemplateSrv } from "@grafana/runtime";
import { TagFilter } from "./TagFilter";
import { getAnnotationTags } from "features/annotations/api";

import { AnnotationsDataFrameViewDTO } from "./types";
import { SIGNAL_FEATURE } from "./TimeSeriesPanel";
import { severityOptions, featureOptions, anomalyTypeOptions, trendOptions } from "utils/optionsUtils";

const featureNames = ["severity", "anomalyType", "trend", "feature", "component"];

interface DropdownField {
  label: string;
  value: string;
}

interface AnnotationEditFormDTO {
  severity: DropdownField;
  anomalyType: DropdownField;
  trend: DropdownField;
  feature: DropdownField;
  component: DropdownField;
  description: string;
  tags: string[];
  measurePoints: string[];
}

interface AnnotationEditorFormProps extends HTMLAttributes<HTMLDivElement> {
  annotation: AnnotationsDataFrameViewDTO;
  timeFormatter: (v: number) => string;
  onSave: () => void;
  onDismiss: () => void;
  signalFeature?: SIGNAL_FEATURE;
  measurePoints?: string[];
}

export const AnnotationEditorForm = React.forwardRef<
  HTMLDivElement,
  AnnotationEditorFormProps
>(
  (
    {
      annotation,
      onSave,
      onDismiss,
      timeFormatter,
      className,
      signalFeature,
      measurePoints,
      ...otherProps
    },
    ref
  ) => {
    const styles = useStyles2(getStyles);
    const panelContext = usePanelContext();
    const clickAwayRef = useRef(null);
    useClickAway(clickAwayRef, () => {
      onDismiss();
    });

    // TODO: temporary hack, for some reason when editing annotation measurePoints is undefined, pulling measure
    // points from the variable in this case. Not sure if its relevant, probably we'll remove it after adding component.
    const variablesMap = Object.fromEntries(
      getTemplateSrv()
        .getVariables()
        .map((it) => [it.id, it])
    );

    const selectedComponent = (variablesMap.Component as QueryVariableModel).current?.value as string || "";

    const features = (annotation?.tags || []).filter((tag) =>
      featureNames.some((fName) => tag.startsWith(fName))
    );
    const featuresMap = Object.fromEntries(
      features.map((item) => [item.split(":")[0], item.split(":")[1]])
    );

    const filteredTags = (annotation?.tags || []).filter(
      (tag) => !featureNames.concat(["mp"]).some((fName) => tag.startsWith(fName))
    );

    const selectedMeasurePoints = (annotation?.tags || [])
      .filter((tag) => tag.startsWith("mp:"))
      .map((item) => item.slice(3));
    const measurePointsOptions = selectedComponent.split("|").map(item => ({ label: item, value: item }));
    //to get the current active measure point since we swaped to component
    const ActiveMeasurePoints: string[] |  undefined  = measurePoints?.filter(mp =>
      measurePointsOptions.some(option => option.value === mp)
    );
    const componentOptions = (variablesMap.Component as QueryVariableModel).options.map((it) => ({ label: it.text as string, value: it.value as string }));

    // TODO: its possible to move alert related tags into form inputs. (i.e.: toggle)
    const [severity, setSeverity] = useState(featuresMap.severity || "");
    const [anomalyType, setAnomalyType] = useState(
      featuresMap.anomalyType || ""
    );
    const [trend, setTrend] = useState(featuresMap.trend || "");
    const [feature, setFeature] = useState(
      featuresMap.feature || signalFeature
    );
    const [description, setDescription] = useState(annotation?.text || "");
    const [measurePointsState, setMeasurePointsState] = useState<string[]>(
      selectedMeasurePoints.length ? selectedMeasurePoints : ActiveMeasurePoints || []
    );
    const [tags, setTags] = useState<string[]>(filteredTags);
    const selectedComponentLabel = componentOptions.find(option => option.value === selectedComponent)?.label || "";
    console.log(selectedComponentLabel);
    const [component, setComponent] = useState(featuresMap.component || selectedComponentLabel);

    const [createAnnotationState, createAnnotation] = useAsyncFn(
      async (event: AnnotationEventUIModel) => {
        const result = await panelContext.onAnnotationCreate!(event);
        if (onSave) {
          onSave();
        }
        return result;
      }
    );

    const [updateAnnotationState, updateAnnotation] = useAsyncFn(
      async (event: AnnotationEventUIModel) => {
        const result = await panelContext.onAnnotationUpdate!(event);
        if (onSave) {
          onSave();
        }
        return result;
      }
    );

    const isUpdatingAnnotation = annotation.id !== undefined;
    const isRegionAnnotation = annotation.time !== annotation.timeEnd;
    const operation = isUpdatingAnnotation
      ? updateAnnotation
      : createAnnotation;
    const stateIndicator = isUpdatingAnnotation
      ? updateAnnotationState
      : createAnnotationState;
    const ts = isRegionAnnotation
      ? `${timeFormatter(annotation.time)} - ${timeFormatter(
          annotation.timeEnd
        )}`
      : timeFormatter(annotation.time);

    const triggerEvent = (
      eventId: string,
      tags: string[],
      severity: string,
      anomalyType: string,
      trend: string,
      feature: string,
      component: string
    ) => {
      const componentId = component.split('-')[0];
      operation({
        id: eventId,
        tags: [
          `severity:${severity}`,
          `anomalyType:${anomalyType}`,
          `trend:${trend}`,
          `feature:${feature}`,
          `component:${componentId}`,
          `equipmentOperation:NOT_EXPECTED`,
          `labeler:${config.bootData.user.name}`,
          ...new Set(tags), // deduplicate
        ],
        description: description || "No description provided",
        from: Math.round(annotation.time!),
        to: Math.round(annotation.timeEnd!),
      });
    }

    const onHandleClickAlertAndSave = () => {
      if (!severity) {
        // Handle case where severity is not selected
        return; // Prevent submission or show error
      }
      tags.push("alert:firing", "alert", "customerInteraction:Sent");
      tags.push(...measurePointsState.map((it) => `mp:${it}`));
      console.log(component);
      triggerEvent(annotation.id, tags, severity, anomalyType, trend, feature!, component);
    };
    const onSubmit = (form: AnnotationEditFormDTO) => {
      tags.push("customerInteraction:Saved");
      tags.push(...measurePointsState.map((it) => `mp:${it}`));
      triggerEvent(annotation.id, tags, severity, anomalyType, trend, feature!, component);
    };

    const form = (
      <div // Annotation editor
        ref={ref}
        className={cx(styles.editor, className)}
        {...otherProps}
      >
        <div className={styles.header}>
          <HorizontalGroup justify={"space-between"} align={"center"}>
            <div className={styles.title}>Add annotation {feature}</div>
            <div className={styles.ts}>{ts}</div>
          </HorizontalGroup>
        </div>
        <div className={styles.editorForm}>
          <Form<AnnotationEditFormDTO> onSubmit={onSubmit}
            defaultValues={{
              severity: severityOptions.find(option => option.value === severity),
              anomalyType: anomalyTypeOptions.find(option => option.value === anomalyType),
              trend: trendOptions.find(option => option.value === trend),
              feature: featureOptions.find(option => option.value === feature),
              component: componentOptions.find(option => option.value === component),
              description,
              tags,
              measurePoints: measurePointsState,
            }}
          >
            {({ register, control, formState: { errors } }) => (
              <>
                  <Field
                    label={"Component"}
                    invalid={!!errors.component}
                    error={errors?.component?.message}
                  >
                    <InputControl
                      render={({ field }) => (
                        <Select
                          {...field}
                          onChange={(e: any) => setComponent(e.label)}
                          options={componentOptions}
                          value={componentOptions.find(option => option.label === component)}
                          />
                      )}
                      control={control}
                      name="component"
                    />
                  </Field>
                  <Field
                    label={"Measure Points"}
                    invalid={!!errors.measurePoints}
                    error={errors?.measurePoints?.message}
                  >
                    <InputControl
                      render={({ field }) => (
                        <Select
                          {...field}
                          onChange={(selectedMp: any) => {
                            setMeasurePointsState(
                              selectedMp.map((item: any) => item.value)
                            );
                          }}
                          options={measurePointsOptions}
                          value={measurePointsState.map((mp) => ({
                            label: mp,
                            value: mp,
                          }))}
                          isMulti
                        />
                      )}
                      control={control}
                      name="measurePoints"
                    />
                  </Field>
                  <Field
                    label={"Severity"}
                    invalid={!!errors.severity}
                    error={errors?.severity?.message}
                  >
                    <InputControl
                      render={({ field }) => (
                        <Select
                          {...field}
                          onChange={(e: any) => {
                            field.onChange(e.value);
                            setSeverity(e.value);
                          }}
                          options={severityOptions}
                          value={severityOptions.find(option => option.value === severity)}
                        />
                      )}
                      control={control}
                      name="severity"
                      rules={{ required: 'Severity is required' }}
                    />
                  </Field>
                  <Field
                    label={"Anomaly Type"}
                    invalid={!!errors.anomalyType}
                    error={errors?.anomalyType?.message}
                  >
                    <InputControl
                      render={({ field }) => (
                        <Select
                          {...field}
                          onChange={(e: any) => {
                            field.onChange(e.value);
                            setAnomalyType(e.value)
                          }}
                          options={anomalyTypeOptions}
                          value={anomalyTypeOptions.find(option => option.value === anomalyType)}
                        />
                      )}
                      control={control}
                      name="anomalyType"
                      rules={{ required: 'Anomaly Type is required' }}
                    />
                  </Field>
                  <Field
                    label={"Trend"}
                    invalid={!!errors.trend}
                    error={errors?.trend?.message}
                  >
                    <InputControl
                      render={({ field }) => (
                        <Select
                          {...field}
                          onChange={(e: any) => {
                            field.onChange(e.value);
                            setTrend(e.value)
                          }}
                          options={trendOptions}
                          value={trendOptions.find(option => option.value === trend)}
                        />
                      )}
                      control={control}
                      name="trend"
                      rules={{ required: 'Trend is required' }}
                    />
                  </Field>
                  <Field
                    label={"Feature"}
                    invalid={!!errors.feature}
                    error={errors?.feature?.message}
                  >
                    <InputControl
                      render={({ field }) => (
                        <Select
                          {...field}
                          onChange={(e: any) => setFeature(e.value)}
                          options={featureOptions}
                          value={featureOptions.find(option => option.value === feature)}
                        />
                      )}
                      control={control}
                      name="feature"
                    />
                  </Field>
                  <Field
                    label={"Description"}
                  >
                    <TextArea
                      {...register("description")}
                      value={description}
                      onChange={(e: any) => setDescription(e.target.value)}
                    />
                  </Field>
                  <Field label={"Tags"}>
                    <InputControl
                      control={control}
                      name="tags"
                      render={({ field: { ref, onChange, ...field } }) => {
                        return (
                          <TagFilter
                            allowCustomValue
                            placeholder="Add tags"
                            onChange={(selectedTags: any) =>
                              setTags(selectedTags)
                            } // Assuming onChange gives you the array of tags directly
                            tagOptions={getAnnotationTags}
                            tags={tags}
                          />
                        );
                      }}
                    />
                  </Field>
                  <HorizontalGroup justify={"flex-end"}>
                    <Button
                      size={"sm"}
                      type={"submit"}
                      disabled={stateIndicator?.loading}
                    >
                      {stateIndicator?.loading ? "Saving" : "Annotate"}
                    </Button>
                    <Button
                      size={"sm"}
                      type={"submit"}
                      disabled={stateIndicator?.loading}
                      onClick={onHandleClickAlertAndSave}
                    >
                      {stateIndicator?.loading
                        ? "Saving & Sending Alert"
                        : "Annotate & Alert"}
                    </Button>
                    <Button
                      size={"sm"}
                      variant="secondary"
                      onClick={onDismiss}
                      fill="outline"
                    >
                      Cancel
                    </Button>
                  </HorizontalGroup>
                </>
           )}
          </Form>
        </div>
      </div>
    );

    return (
      <>
        <div className={styles.backdrop} />
        <div ref={clickAwayRef}>{form}</div>
      </>
    );
  }
);

AnnotationEditorForm.displayName = "AnnotationEditorForm";

const getStyles = (theme: GrafanaTheme2) => {
  return {
    backdrop: css`
      label: backdrop;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      z-index: ${theme.zIndex.navbarFixed};
    `,
    editorContainer: css`
      position: absolute;
      top: calc(100% + 10px);
      transform: translate3d(-50%, 0, 0);
    `,
    editor: css`
      background: ${theme.colors.background.primary};
      box-shadow: ${theme.shadows.z3};
      z-index: ${theme.zIndex.dropdown};
      border: 1px solid ${theme.colors.border.weak};
      border-radius: ${theme.shape.borderRadius()};
      width: 460px;
    `,
    editorForm: css`
      padding: ${theme.spacing(1)};
    `,
    header: css`
      border-bottom: 1px solid ${theme.colors.border.weak};
      padding: ${theme.spacing(1.5, 1)};
    `,
    title: css`
      font-weight: ${theme.typography.fontWeightMedium};
    `,
    ts: css`
      font-size: ${theme.typography.bodySmall.fontSize};
      color: ${theme.colors.text.secondary};
    `,
  };
};
